<div class="edfx-badge" [style]="getCustomColorBadgeStyle()" [class]="getBadgeStyleClasses()">
  <span data-cy="edfx-badge-txt">{{ txt }}</span>

  <ng-container *ngIf="!isHeader">
    <span class="material-icons edfx-badge-direction" *ngIf="activityDirection">{{ activityDirection === "up" ? "north" : "south" }}</span>
    <edfx-info-icon
      [tooltip]="information"
      [tooltipPlacement]="tooltipPlacement"
      [tooltipMaxWidth]="tooltipMaxWidth"
      [html]="true"
      *ngIf="information"
      class="edfx-badge-info"
      [outlined]="false"
      [color]="iconColor"
    ></edfx-info-icon>
  </ng-container>
</div>
