import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { AppComponent } from './app.component';
import { EdfxBadgeModule } from './edfx-badge/edfx-badge.module';
import { EdfxLoadingSpinnerModule } from './edfx-loading-spinner/edfx-loading-spinner.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    EdfxBadgeModule,
    EdfxLoadingSpinnerModule,
    TranslocoModule,
    TranslocoLocaleModule,
    TranslocoRootModule
  ],
  providers: [],
  bootstrap: []
})
export class AppModule {
  constructor(injector: Injector) {
    const ttcPitSmartCardElement = createCustomElement(AppComponent, {
      injector
    });
    customElements.define('edfx-pit-ttc-smart-card', ttcPitSmartCardElement);
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngDoBootstrap() {}
}
