import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Entitlements, FREQUENCY_DAILY, FREQUENCY_MONTHLY, IMPLIED_RATING_CLASSIFICATIONS, MOMENT_FORMATS } from 'interfaces/constants';
import * as moment from 'moment';
import { Subject, catchError, firstValueFrom, of, takeUntil } from 'rxjs';
import { ApiService } from './api.service';
import { IEntitlement } from './interfaces/entitlement.interface';
import {
  ICompanyModel,
  IEntitySearchResponse,
  IHistoricalPd,
  IHistoricalPdDrivers,
  IModelData,
  IPDHistoricalInputPayload,
  ISmartCardCustomData,
  ISmartCardType
} from './types';
import { Utils } from './utils/utils';

@Component({
  selector: 'edfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class AppComponent implements OnInit, OnDestroy, OnChanges {
  private readonly destroy$ = new Subject<undefined>();
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  loading = false;
  invalidToken = false;
  hasEntitlement = false;
  asOfDateBottomTxt!: string;
  mainBadgeTxt!: string;
  latestPd!: number | undefined | null;
  latestKnownPdDate!: string | undefined;
  latestKnownPdValue!: number | undefined;
  oneYrPdKpi!: string | undefined;
  oneYrPdDateLabel!: string | undefined;
  mainBadgeColor!: string;
  mainBadgeActivityDirection!: string;
  fsoInput!: IPDHistoricalInputPayload;
  lastYearFsoInput!: IPDHistoricalInputPayload;
  entityId: string;
  modelData: IModelData;
  selectedSmartCard: ISmartCardType;
  invalidEntityId = false;
  invalidDate = false;
  smartCardCustomData: ISmartCardCustomData;

  @Input() inputData!: string | ISmartCardCustomData;
  @Input() token!: string;
  input: { entityId: string; startDate: string; endDate: string; frequency: string; fso: boolean };

  constructor(private apiService: ApiService, private ref: ChangeDetectorRef, private translocoService: TranslocoService) {
    // Only for dev testing, comment out for building the web element!
    /*this.smartCardCustomData = {
      entityId: 'GB03977902',
      smartCardType: ISmartCardType.oneYrTTCPDImpliedRating
    };
    this.token = // eslint-disable-next-line
    '';
    this.inputData = JSON.stringify(this.smartCardCustomData);
    */

  }

  ngOnInit(): void {
    if (!Utils.isNullOrUndefined(this.inputData)) {
      this.getSmartCardCustomDataFromInput();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData'] && !Utils.isNullOrUndefined(changes['inputData'].currentValue)) {
      this.inputData = changes['inputData'].currentValue;
      this.getSmartCardCustomDataFromInput();
    }

    if (changes['token'] && !Utils.isNullOrUndefined(changes['token'].currentValue)) {
      this.getSmartCardCustomDataFromInput();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  getSmartCardCustomDataFromInput(){
    if(typeof this.inputData === 'string'){
      this.smartCardCustomData = JSON.parse(this.inputData) as ISmartCardCustomData;
    }
    else if (typeof this.inputData === 'object'){
      this.smartCardCustomData = this.inputData;
    }
    this.getSmartCardData(this.smartCardCustomData.smartCardType);
  }

  getSmartCardData(type: ISmartCardType): void {
    this.selectedSmartCard = type;
    const entitlement_list = [
      Entitlements.MIR_MEDIAN_CREDIT_SPREAD,
      Entitlements.ORBIS_ALL_COMPANIES,
      Entitlements.PRIVATE_ENTITLEMENT,
      Entitlements.PUBLIC_ENTITLEMENT,
      Entitlements.PUBLIC_PLUS_ENTITLEMENT
    ];
    if (!Utils.isNullOrUndefined(this.token)) {
      this.apiService.setToken(this.token);
    }
    if (this.smartCardCustomData?.asOfDate && !moment(this.smartCardCustomData.asOfDate).isValid()) {
      this.invalidDate = true;
      this.hasEntitlement = true;
      return;
    }
    this.loading = true;
    this.apiService
      .getEntitlements(entitlement_list)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.invalidToken = true;
          }
          this.hasEntitlement = true;
          return of();
        })
      )
      .subscribe(entitlements => this.onEntitlementsReceived(entitlements, type));
  }

  onEntitlementsReceived(entitlements: IEntitlement[], type: ISmartCardType) {
    this.hasEntitlement = entitlements?.some(
      entitlement => entitlement.name === Entitlements.PRIVATE_ENTITLEMENT || entitlement.name === Entitlements.PUBLIC_ENTITLEMENT
    );
    if (!this.hasEntitlement) {
      this.loading = false;
      return;
    }
    this.getModelData();
  }

  getModelData(): void {
    if (!this.smartCardCustomData.entityId) {
      return;
    }
    this.entityId = this.smartCardCustomData.entityId;

    if (this.smartCardCustomData.asOfDate) {
      this.latestKnownPdDate = this.smartCardCustomData.asOfDate;
    } else {
      this.latestKnownPdDate = this.today;
    }

    this.getEntityId();
  }

  setModelData(): void {
    this.apiService
      .getModel(this.entityId, this.latestKnownPdDate)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loading = false;
          return of(err);
        })
      )
      .subscribe((modelD: ICompanyModel) => {
        this.invalidToken = false;
        if (!modelD) {
          return;
        }
        const publicCompany = modelD.modelId === 'EDF9';

        this.modelData = {
          latestPdDate: modelD.latestKnownPdDate,
          latestKnownPdValue: modelD.latestKnownPdValue,
          publicCompany
        };

        switch (this.selectedSmartCard) {
          case ISmartCardType.oneYrPiTPD:
            this.getOneYrPiPData(false);
            break;
          case ISmartCardType.oneYrTTCPD:
            this.getOneYrPiPData(true);
            break;
          case ISmartCardType.oneYrPiTPDImpliedRating:
            this.getOneYrPiPImpliedRatingData(false);
            break;
          case ISmartCardType.oneYrTTCPDImpliedRating:
            this.getOneYrPiPImpliedRatingData(true);
            break;

          default:
            return;
        }
      });
  }

  getEntityId(): void {
    const currentEntityId = this.entityId;
    this.apiService
      .getEntityId(currentEntityId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loading = false;
          return of(err);
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.loading = false;
          return;
        }
        if (entityIdResult.entities.length === 0) {
          this.getCustomEntityId(currentEntityId);
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.setModelData();
      });
  }

  getCustomEntityId(currentEntityId: string) {
    this.apiService
      .getEntityId(currentEntityId, true)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loading = false;
          return of(err);
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.loading = false;
          return;
        }
        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        if (Utils.isNullOrUndefined(entityIdResult?.entities) || entityIdResult?.entities?.length < 1) {
          this.invalidEntityId = true;
          this.loading = false;
          return;
        }
        this.setModelData();
      });
  }

  getPdDriversParams(isPublic: boolean, isTTC: boolean, latestPdDate: string, isIR?: boolean): IPDHistoricalInputPayload {
    let startDateSubstract = isTTC ? 5 : 2;
    if (isTTC && isPublic) {
      startDateSubstract = 1;
    }
    const sDate = Utils.getLatestDate(latestPdDate).clone().subtract(startDateSubstract, 'y').format(MOMENT_FORMATS.payload);

    this.input = {
      entityId: this.entityId,
      startDate: sDate,
      endDate: Utils.getLatestDate(latestPdDate).format(MOMENT_FORMATS.payload),
      frequency: isPublic ? FREQUENCY_DAILY : FREQUENCY_MONTHLY,
      fso: isTTC && !isIR
    };

    return this.input;
  }

  getPDYoY(pdData: IHistoricalPd[]): number | undefined {
    const lastPd = pdData[pdData.length - 1];
    const lastYearPds = pdData.filter((pd: IHistoricalPd) => moment(pd.date).isSameOrAfter(moment(lastPd.date).subtract(1, 'y')));
    const firstPD = lastYearPds[0];
    if (!lastPd.pd || !firstPD.pd) {
      return undefined;
    }
    const diff = (lastPd.pd - firstPD.pd) * 10000;

    return diff;
  }

  getOneYrPiPData(ttc: boolean): void {
    if (!this.smartCardCustomData.entityId) {
      this.loading = false;
      return;
    }
    if (!this.modelData || Utils.isNullOrUndefined(this.modelData.publicCompany) || !this.modelData.latestPdDate) {
      this.loading = false;
      return;
    }

    this.apiService
      .getMonthlyHistoricalPdDrivers(this.getPdDriversParams(this.modelData.publicCompany, ttc, this.modelData.latestPdDate))
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loading = false;
          return of(err);
        })
      )
      .subscribe((companyRes: IHistoricalPdDrivers) => {
        if (!companyRes.pds || companyRes.pds.length === 0 || !this.modelData.latestPdDate) {
          this.loading = false;
          return;
        }

        const pds = companyRes?.pds;
        this.latestPd = pds[pds.length - 1].pd;
        this.oneYrPdKpi = this.getOneYrPdKpi();

        this.oneYrPdDateLabel = this.getOneYrPdDateLabel();
        const diff = this.getPDYoY(pds);
        this.mainBadgeTxt = diff.toFixed(0) + ' bps YoY';
        this.mainBadgeActivityDirection = diff.toFixed(0) !== '0' ? this.getArrowIconDirection(diff) : '';
        this.mainBadgeTxt = this.mainBadgeTxt.replace('-', '');
        this.mainBadgeColor = this.getBadgeEvolutionColor(diff, false);
        this.asOfDateBottomTxt = this.getAsOfDateLabel(pds[pds.length - 1].date);

        this.ref.detectChanges();
        this.loading = false;
      });
  }

  getOneYrPiPImpliedRatingData(ttc: boolean): void {
    if (!this.smartCardCustomData.entityId) {
      this.loading = false;
      return;
    }
    if (!this.modelData || Utils.isNullOrUndefined(this.modelData.publicCompany) || !this.modelData.latestPdDate) {
      this.loading = false;
      return;
    }

    if (this.modelData.publicCompany && ttc) {
      this.asOfDateBottomTxt = this.getAsOfDateLabel(this.modelData.latestPdDate ?? '');
      this.loading = false;
      return;
    }

    this.apiService
      .getMonthlyHistoricalPdDrivers(this.getPdDriversParams(this.modelData.publicCompany, ttc, this.modelData.latestPdDate))
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loading = false;
          return of(err);
        })
      )
      .subscribe((companyRes: IHistoricalPdDrivers) => {
        if (!companyRes.pds || companyRes.pds.length === 0) {
          this.loading = false;
          return;
        }

        const pds = companyRes.pds;
        const mLastYearDate = Utils.getLatestDate(pds[pds.length - 1].date).subtract(1, 'years');
        const filteredData = pds?.filter(x => moment(x.date).isSameOrAfter(mLastYearDate));

        const lastYearLatestPd = filteredData.length > 1 ? filteredData[0] : null;
        const lastYearIr = lastYearLatestPd?.impliedRating;
        const latestKnownIr = pds[pds.length - 1].impliedRating;
        const latestKnownPdDate = pds[pds.length - 1].date;
        let oneYrPdImpliedRatingChange: number;

        if (lastYearIr) {
          const currentIrVal = IMPLIED_RATING_CLASSIFICATIONS.indexOf(latestKnownIr) + 1;
          const lastYearIrVal = IMPLIED_RATING_CLASSIFICATIONS.indexOf(lastYearIr) + 1;
          oneYrPdImpliedRatingChange = currentIrVal - lastYearIrVal;
        } else {
          oneYrPdImpliedRatingChange = 0;
        }

        this.computeNotchesTxt(oneYrPdImpliedRatingChange).then(txt => {
          this.mainBadgeTxt = txt;
          this.mainBadgeColor = this.getBadgeEvolutionColor(oneYrPdImpliedRatingChange, ttc);
          this.mainBadgeActivityDirection = this.getArrowIconDirection(-oneYrPdImpliedRatingChange);
          this.oneYrPdKpi = pds[pds.length - 1].impliedRating;
          this.asOfDateBottomTxt = this.getAsOfDateLabel(latestKnownPdDate ?? '');

          this.ref.detectChanges();
          this.loading = false;
        });
      });
  }

  getAsOfDateLabel(latestKnownPdDate: string): string {
    return `As of ${latestKnownPdDate ? moment(latestKnownPdDate).format('MMMM D, YYYY') : undefined}`;
  }

  async computeNotchesTxt(change?: number) {
    if (Utils.isNullOrUndefined(change)) {
      return '';
    }
    const evol = -change === 0 ? '0' : -change <= 0 ? 'D' : 'U';

    const notch = await firstValueFrom(
      this.translocoService.selectTranslate('NOTCHES.NOTCH', {
        notchCount: Math.abs(change)
      })
    );
    const evolTxt = await firstValueFrom(this.translocoService.selectTranslate('NOTCHES.EVOLUTION', { evol }));
    const yoy = this.translocoService.translate('YOY');

    return notch + ' ' + evolTxt + yoy;
  }

  private getOneYrPdDateLabel(): string {
    return this.latestPd ? 'As of ' + moment(this.latestKnownPdDate, 'YYYY-MM-DD').format('MMMM D, YYYY') : '';
  }

  private getOneYrPdKpi(): string {
    return this.latestPd ? (this.latestPd * 100).toFixed(2) + '%' : '';
  }
  public getArrowIconDirection(evolution: number | string | undefined): '+' | '-' | '' {
    return !!evolution ? (Number(evolution) > 0 ? '+' : '-') : '';
  }

  public getBadgeEvolutionColor(evolution: number, isTTC: boolean): 'red' | 'green' | 'gray' {
    return evolution !== 0 ? (evolution < 0 ? 'green' : 'red') : 'gray';
    // if (isTTC) {
    //   return evolution !== 0 ? (evolution > 0 ? 'green' : 'red') : 'gray';
    // } else {
    //   return evolution !== 0 ? (evolution < 0 ? 'green' : 'red') : 'gray';
    // }
  }
}
