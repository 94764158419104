import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CustomTranslocoLoader } from './transloco-http-loader';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@NgModule({
  imports: [TranslocoMessageFormatModule.forRoot()],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en'],
        reRenderOnLangChange: false,
        prodMode: true
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: CustomTranslocoLoader }
  ]
})
export class TranslocoRootModule {}
