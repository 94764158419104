import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MdbTooltipPosition } from 'mdb-angular-ui-kit/tooltip';
import { EdfxColors } from '../../../../../utils/edfx-style';

@Component({
  selector: 'edfx-badge',
  templateUrl: './edfx-badge.component.html',
  styleUrls: ['./edfx-badge.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EdfxBadgeComponent {
  @Input() txt = '';
  @Input() isHeader = false;
  @Input() color!: string;
  @Input() customColor!: string;
  @Input() outlined = false;
  @Input() information = '';
  @Input() activityDirection = '';
  @Input() size = 'S';
  @Input() roundedPill = false;
  @Input() tooltipPlacement: MdbTooltipPosition = 'top';
  @Input() tooltipMaxWidth!: string;

  constructor() {}

  get iconColor(): string {
    if (this.outlined) {
      // If there is a custom color, use that
      if (this.customColor) {
        return this.customColor;
      }

      // If there is no a custom color but there is a predefined color, use that
      if (this.color) {
        switch (this.color) {
          case 'red':
            return EdfxColors.red;
          case 'green':
            return EdfxColors.darkGreen;
          case 'gray':
            return EdfxColors.gray3;
          case 'light-gray':
            return EdfxColors.lightGray;
          case 'light-gray-3':
            return EdfxColors.lightGray3;
          case 'white':
            return EdfxColors.white;
          case 'blue':
            return EdfxColors.robinBlue;
          case 'instrument-badge-gray':
            return EdfxColors.lightGray3;
          default:
            return EdfxColors.gray3;
        }
      }

      // Fall back on gray
      return EdfxColors.gray3;
    }

    // If it is not outlined, use white
    return EdfxColors.white;
  }

  getBadgeSizeClass(): string {
    return `edfx-badge-size-${this.size}`;
  }

  getPredefinedBadgeStyleClass(): string {
    let predefinedStyleClass = this.outlined ? `edfx-badge-${this.color}-outlined` : `edfx-badge-${this.color}`;
    predefinedStyleClass = this.roundedPill ? predefinedStyleClass + ' edfx-badge-rounded-pill' : predefinedStyleClass;
    return this.customColor ? '' : predefinedStyleClass;
  }

  getBadgeStyleClasses(): string {
    return `${this.getBadgeSizeClass()} ${this.getPredefinedBadgeStyleClass()} `;
  }

  getCustomColorBadgeStyle(): object {
    const customColorStyle = {
      backgroundColor: this.outlined ? 'transparent' : this.customColor,
      border: this.outlined ? `1px solid ${this.customColor}` : '',
      color: this.outlined ? this.customColor : EdfxColors.white
    };

    return this.customColor ? customColorStyle : {};
  }
}
