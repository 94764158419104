import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'edfx-loading-spinner',
  templateUrl: './edfx-loading-spinner.component.html',
  styleUrls: ['./edfx-loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EdfxLoadingSpinnerComponent {
  @Input() blackBackground!: boolean;
  @Input() fullScreen!: boolean;
  @Input() small!: boolean;
  @Input() title = '';
  @Input() loadingText = 'Loading...';
  @Input() showText = false;
}
