import { TranslocoLoader } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import translations from '../../assets/i18n/en.ttcPit.json';

@Injectable({ providedIn: 'root' })
export class CustomTranslocoLoader implements TranslocoLoader {
  constructor() {}

  getTranslation() {
    return Promise.resolve(translations);
  }
}
