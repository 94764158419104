import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdfxBadgeComponent } from './edfx-badge.component';
import { EdfxInfoIconModule } from '../edfx-info-icon/edfx-info-icon.module';

@NgModule({
  declarations: [EdfxBadgeComponent],
  exports: [EdfxBadgeComponent],
  imports: [CommonModule, EdfxInfoIconModule],
})
export class EdfxBadgeModule {}
