<div class="edfx-metric-card edfx-metric-card-hover-effect" *ngIf="smartCardCustomData">
  <div data-cy="smartCardTitle" class="edfx-metric-card-title">{{ smartCardCustomData.smartCardType }}</div>
  <ng-container *ngIf="!invalidDate && !invalidEntityId && !invalidToken && hasEntitlement">
    <ng-container *ngIf="!loading; else showLoading">
      <span data-cy="smartCardKpi" class="edfx-metric-card-kpi"> {{ oneYrPdKpi ? oneYrPdKpi : "N/A" }}</span>
      <div class="edfx-metric-card-badges edfx-flex-column">
        <edfx-badge
          *ngIf="mainBadgeTxt"
          class="edfx-metric-card-badges-main"
          [color]="mainBadgeTxt ? mainBadgeColor : 'gray'"
          [outlined]="true"
          [txt]="mainBadgeActivityDirection + '' +mainBadgeTxt"
          size="M"
        ></edfx-badge>
      </div>
      <span *ngIf="asOfDateBottomTxt" data-cy="smartCardDate" class="edfx-metric-card-asof">{{ asOfDateBottomTxt }}</span>
    </ng-container>
    <ng-template #showLoading>
      <edfx-loading-spinner></edfx-loading-spinner>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="invalidEntityId">
    <p>Invalid Entity ID</p>
  </ng-container>
  <ng-container *ngIf="invalidDate">
    <p>Invalid Input Date</p>
  </ng-container>
  <ng-container *ngIf="invalidToken">
    <p>Invalid Token</p>
  </ng-container>
  <ng-container *ngIf="!hasEntitlement && !loading">
    <p class="token-invalid-message">User does not have EDF-X access. Please contact your Moody's representative.</p>
  </ng-container>
</div>
